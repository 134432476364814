import axios from "config/axios";
import { GenericResponse } from "shared/common";
import { Address } from "shared/types";

export interface JobForm {
  addressCardNumber: string | null;
  address: Address | null;
  birthDate: string | null;
  birthPlace: string | null; //Date
  createdBy: number | null;
  createdOn: string | null; // Instant, ISO string format
  email: string | null;
  facebook: string | null;
  id: number | null;
  idCardNumber: string | null;
  instagram: string | null;
  isAccommodationRequired: boolean | null;
  isCareTakingRequired: boolean | null;
  isDailyMedicine: boolean | null;
  isExtraInsuranceRequired: boolean | null;
  isLegalAssistance: boolean | null;
  isTravelWithOwnCar: boolean | null;
  likedRegion: string | null;
  name: string | null;
  phoneForeign: string | null;
  phoneHu: string | null;
  plans: string | null;
  profilePic: string | null;
  tempAddress: Address | null;
  tenantId: number | null;
  updatedBy: number | null;
  updatedOn: string | null; // Instant, ISO string format
  viber: string | null;
  jobLanguageSkillEntries: JobLanguageSkill[];
  jobQualificationEntries: JobQualification[];
  jobExperienceEntries: JobExperience[];
  jobPositionEntries: JobPosition[];
}

export interface JobQualification {
  certificateNumber: string;
  certificateYear: string;
  createdBy: number;
  createdOn: string;
  id: number;
  jobQuestionnaireId: number;
  profession: string;
  qualificationCity: string;
  qualificationSchoolName: string;
  tenantId: number;
  updatedBy: number;
  updatedOn: string;
}

export interface JobPosition {
  createdBy: number;
  createdOn: string;
  id: number;
  jobDescription: string;
  jobQuestionnaireId: number;
  tenantId: number;
  updatedBy: number;
  updatedOn: string;
}

export interface JobLanguageSkill {
  createdBy: number;
  createdOn: string;
  tenantId: number;
  jobQuestionnaireId: number;
  language: string;
  oralLevel: string;
  writtenLevel: string;
  updatedBy: number;
  updatedOn: string;
}

export interface JobExperience {
  createdBy: number;
  createdOn: string;
  experience: string;
  fromDate: string;
  id: number;
  jobQuestionnaireId: number;
  jobTitle: string;
  referencePersonEmail: string;
  referencePersonName: string;
  referencePersonPhone: string;
  tenantId: number;
  toDate: string;
  updatedBy: number;
  updatedOn: string;
}

const ENDPOINT = "/job-questionnaire";

export const createJobQuestionnaire = (param: JobForm, password: string, tenantId: number) =>
  axios.post<GenericResponse<JobForm>>(`${ENDPOINT}`, {
    jobQuestionnaireEntry: param,
    password,
    tenantId,
  });

export const addProfilePic = ({
  jobQuestionnaireId,
  tenantId,
  file,
}: {
  jobQuestionnaireId: number;
  tenantId: number;
  file: File;
}) => {
  const formData = new FormData();
  formData.set("jobQuestionnaireId", jobQuestionnaireId.toString());
  formData.set("tenantId", tenantId.toString());
  formData.set("file", file);

  return axios.post(`${ENDPOINT}/update-profile`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}; //COMMENT_ADMIN
