import { Button, Card, Container, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Loading from "components/Loading";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  addProfilePic,
  createJobQuestionnaire,
  JobExperience,
  JobLanguageSkill,
  JobPosition,
  JobQualification,
} from "shared/network/job-questionnaire.api";
import { Address } from "shared/types";
import AcceptTos from "views/Report/AcceptTos";
import ContactDataCard from "./components/ContactDataCard";
import DetailsDataCard from "./components/DetailsDataCard";
import EducationDataCard from "./components/EducationDataCard";
import JobDataCard from "./components/JobDataCard";
import LanguageDataCard from "./components/LanguageDataCard";
import PersonalDataCard from "./components/PersonalDataCard";
import { PASSWORD_POLICY_REGEX } from "config/constants";
import PasswordTextField from "components/PasswordTextField";
import { RouteComponentProps, useLocation } from "react-router";

type FormValues = {
  addressCardNumber: string | null;
  address: Address | null;
  birthDate: string | null;
  birthPlace: string | null; //Date
  createdBy: number | null;
  createdOn: string | null; // Instant, ISO string format
  email: string | null;
  facebook: string | null;
  id: number | null;
  idCardNumber: string | null;
  instagram: string | null;
  isAccommodationRequired: boolean | null;
  isCareTakingRequired: boolean | null;
  isDailyMedicine: boolean | null;
  isExtraInsuranceRequired: boolean | null;
  isLegalAssistance: boolean | null;
  isTravelWithOwnCar: boolean | null;
  likedRegion: string | null;
  name: string | null;
  phoneForeign: string | null;
  phoneHu: string | null;
  plans: string | null;
  profilePic: string | null;
  tempAddress: Address | null;
  tenantId: number | null;
  updatedBy: number | null;
  updatedOn: string | null; // Instant, ISO string format
  viber: string | null;
  jobLanguageSkillEntries: JobLanguageSkill[];
  jobQualificationEntries: JobQualification[];
  jobExperienceEntries: JobExperience[];
  jobPositionEntries: JobPosition[];
  file: any;
  password: string;
};

const JobFormCreate = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const query = new URLSearchParams(location.search);
  const tenantId = query.get("tenantId");

  const user = useSelector((state: RootState) => state?.authentication?.account?.user);
  const form = useForm();
  const { handleSubmit, watch } = form;
  const [loading, setLoading] = useState(false);
  const [isTosOk, setIsTosOk] = useState(false);

  async function onSubmit(values: FormValues) {
    setLoading(true);
    const isTempAddressRequired = !!(
      values?.tempAddress?.country ||
      values?.tempAddress?.zipCode ||
      values?.tempAddress?.city ||
      values?.tempAddress?.areaName
    );
    try {
      const { data } = await createJobQuestionnaire(
        {
          ...values,
          tempAddress: isTempAddressRequired ? values.tempAddress : null,
        },
        values.password,
        tenantId ? Number(tenantId) : 1,
      );
      if (values.file?.[0] && data?.item?.id) {
        await addProfilePic({
          jobQuestionnaireId: data?.item?.id,
          tenantId: tenantId ? Number(tenantId) : 1,
          file: values.file[0],
        });
      }
      enqueueSnackbar(t("jobForm.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("jobForm.error"), { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <Container maxWidth="md">
      <Loading open={loading} />
      {!user && (
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: 24,
            textAlign: "center",
            padding: 16,
          }}
        >
          {t("jobForm.title")}
        </Typography>
      )}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box pb={2}>
            <PersonalDataCard />
          </Box>
          <Box pb={2}>
            <ContactDataCard />
          </Box>
          <Box pb={2}>
            <EducationDataCard />
          </Box>
          <Box pb={2}>
            <LanguageDataCard />
          </Box>
          <Box pb={2}>
            <JobDataCard />
          </Box>
          <Box pb={2}>
            <DetailsDataCard />
          </Box>
          <Box
            pb={2}
            style={{
              marginBottom: 16,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                padding: 16,
                marginBottom: 16,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box display="block" style={{ maxWidth: 400 }}>
                <Controller
                  name="password"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t("validation.required"),
                    },
                    validate: value => {
                      if (value && !value.match(PASSWORD_POLICY_REGEX)) {
                        return t("common:passwordPolicy").toString();
                      }
                      if (value !== watch("password2")) {
                        return t("common:passwordMismatch").toString();
                      }
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <PasswordTextField
                      {...field}
                      onBlur={event => field.onChange(event?.target?.value?.trim())}
                      ref={undefined}
                      type="password"
                      label={t("registration.formValues.password")}
                      style={{ marginBottom: 16 }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  name="password2"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t("validation.required"),
                    },
                    validate: value => {
                      if (value && !value.match(PASSWORD_POLICY_REGEX)) {
                        return t("common:passwordPolicy").toString();
                      }
                      if (value !== watch("password")) {
                        return t("common:passwordMismatch").toString();
                      }
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <PasswordTextField
                      {...field}
                      onBlur={event => field.onChange(event?.target?.value?.trim())}
                      ref={undefined}
                      type="passwordConfirmation"
                      label={t("registration.formValues.passwordConfirm")}
                      style={{ maxWidth: 400 }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <AcceptTos setIsTosOk={setIsTosOk} />
            </Card>
            <Button disabled={!isTosOk || loading} type="submit">
              {t("common:button.save")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Container>
  );
};

export default JobFormCreate;
