import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, RemoveCircle } from "@material-ui/icons";
import { COLORS } from "config/theme";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const AUSTRIA_STATES = [
  "Burgenland",
  "Carinthia",
  "LowerAustria",
  "Salzburg",
  "Styria",
  "Tyrol",
  "UpperAustria",
  "Vienna",
  "Vorarlberg",
];

const DetailsDataCard = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { control, watch } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: "jobPositionEntries",
  });

  const isNotAccepted = watch("isTosChecked") !== "true" || watch("isGdprChecked") !== "true";

  return (
    <Card style={{ padding: 16 }}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.details.title1")}
      </Typography>
      <Grid container spacing={1} style={{ marginBottom: 8 }}>
        {fields.map((field, index) => {
          return (
            <Grid key={field.id} item xs={12} sm={4}>
              <Controller
                name={`jobPositionEntries.${index}.jobDescription`}
                defaultValue=""
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    size="small"
                    label={t("jobForm.details.jobDescription")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                    InputProps={{
                      startAdornment: (
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: 16,
                            color: COLORS.mainLight,
                            paddingLeft: 4,
                            paddingRight: 4,
                          }}
                        >
                          {index + 1}.
                        </Typography>
                      ),
                      endAdornment: (
                        <Tooltip title={t("common:button.delete")}>
                          <IconButton
                            size="small"
                            style={{ color: COLORS.mainLight }}
                            onClick={() => remove(index)}
                          >
                            <RemoveCircle fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          );
        })}
        <Grid item xs={12} sm={4}>
          <Button
            size="small"
            variant="outlined"
            fullWidth
            startIcon={<AddBox />}
            style={{ height: 40 }}
            onClick={() =>
              append({
                language: "",
                oralLevel: "",
                writtenLevel: "",
              })
            }
          >
            {t("jobForm.details.addPosition")}
          </Button>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: 8 }} />
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.details.title2")}
      </Typography>
      <Box>
        <Controller
          name="isTravelWithOwnCar"
          defaultValue={"false"}
          render={({ field }) => (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <Box display="flex" alignItems="center">
                <Typography style={{ flex: 1 }}>
                  {t("jobForm.details.isTravelWithOwnCar")}
                </Typography>
                <RadioGroup {...field}>
                  <Box display="flex">
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("jobForm.details.yes")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("jobForm.details.no")}
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </FormControl>
          )}
        />
      </Box>
      <Divider />
      <Box>
        <Controller
          name="isCareTakingRequired"
          defaultValue={"false"}
          render={({ field }) => (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <Box display="flex" alignItems="center">
                <Typography style={{ flex: 1 }}>
                  {t("jobForm.details.isCareTakingRequired")}
                </Typography>
                <RadioGroup {...field}>
                  <Box display="flex">
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("jobForm.details.yes")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("jobForm.details.no")}
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </FormControl>
          )}
        />
      </Box>
      <Divider />
      <Box>
        <Controller
          name="isAccommodationRequired"
          defaultValue={"false"}
          render={({ field }) => (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <Box display="flex" alignItems="center">
                <Typography style={{ flex: 1 }}>
                  {t("jobForm.details.isAccommodationRequired")}
                </Typography>
                <RadioGroup {...field}>
                  <Box display="flex">
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("jobForm.details.yes")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("jobForm.details.no")}
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </FormControl>
          )}
        />
      </Box>
      <Divider />
      <Box>
        <Box display="flex" alignItems="center" p={"8px 0"}>
          <Typography style={{ flex: 1 }}>{t("jobForm.details.likedRegion")}</Typography>
          <Controller
            name="likedRegion"
            defaultValue=""
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                size="small"
                style={{ maxWidth: 200 }}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
                select
              >
                {AUSTRIA_STATES?.sort((a, b) =>
                  t(`jobForm.austriaStates.${a}`)?.localeCompare(t(`jobForm.austriaStates.${b}`)),
                )?.map(state => (
                  <MenuItem key={state} value={state}>
                    {t(`jobForm.austriaStates.${state}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Box>
      </Box>
      <Divider style={{ marginBottom: 8 }} />
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.details.title3")}
      </Typography>
      <Box>
        <Controller
          name="isExtraInsuranceRequired"
          defaultValue={"false"}
          render={({ field }) => (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <Box display="flex" alignItems="center">
                <Typography style={{ flex: 1 }}>
                  {t("jobForm.details.isExtraInsuranceRequired")}
                </Typography>
                <RadioGroup {...field}>
                  <Box display="flex">
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("jobForm.details.yes")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("jobForm.details.no")}
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Controller
          name="isLegalAssistance"
          defaultValue={"false"}
          render={({ field }) => (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <Box display="flex" alignItems="center">
                <Typography style={{ flex: 1 }}>
                  {t("jobForm.details.isLegalAssistance")}
                </Typography>
                <RadioGroup {...field}>
                  <Box display="flex">
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("jobForm.details.yes")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("jobForm.details.no")}
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Controller
          name="isDailyMedicine"
          defaultValue={"false"}
          render={({ field }) => (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <Box display="flex" alignItems="center">
                <Typography style={{ flex: 1 }}>{t("jobForm.details.isDailyMedicine")}</Typography>
                <RadioGroup {...field}>
                  <Box display="flex">
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("jobForm.details.yes")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("jobForm.details.no")}
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </FormControl>
          )}
        />
      </Box>
      <Box>
        <Controller
          name="isWorkbookAvailable"
          defaultValue={"false"}
          render={({ field }) => (
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <Box display="flex" alignItems="center">
                <Typography style={{ flex: 1 }}>
                  {t("jobForm.details.isWorkbookAvailable")}
                </Typography>
                <RadioGroup {...field}>
                  <Box display="flex">
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label={t("jobForm.details.yes")}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label={t("jobForm.details.no")}
                    />
                  </Box>
                </RadioGroup>
              </Box>
            </FormControl>
          )}
        />
      </Box>
    </Card>
  );
};

export default DetailsDataCard;
