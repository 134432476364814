import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonBase, Card, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { AREA_TYPES, NUMBER_FORMAT } from "config/constants";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const ContactDataCard = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { register, watch } = form;
  const idImage = watch("file")?.[0];
  const tempAddress = watch("tempAddress");
  const [imgSrc, setImgSrc] = useState("");

  useEffect(() => {
    if (idImage) {
      const reader = new FileReader();
      reader.onload = e => {
        const result = e.target?.result;
        if (result && typeof result === "string") {
          setImgSrc(result);
        }
      };
      reader.readAsDataURL(idImage);
    }
  }, [idImage]);

  const isTempAddressRequired = !!(
    tempAddress?.country ||
    tempAddress?.zipCode ||
    tempAddress?.city ||
    tempAddress?.areaName
  );

  return (
    <Card style={{ padding: 16 }}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.personalData.title")}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.personalData.name")}*
          </Typography>
          <Controller
            name="name"
            defaultValue=""
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                size="small"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.personalData.birthName")}*
          </Typography>
          <Controller
            name="birthName"
            defaultValue=""
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.personalData.birthPlace")}*
          </Typography>
          <Controller
            name="birthPlace"
            defaultValue=""
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.personalData.birthDate")}*
          </Typography>
          <Controller
            name="birthDate"
            rules={{
              required: t("validation.required").toString(),
            }}
            defaultValue={null}
            render={({ field, fieldState }) => (
              <KeyboardDatePicker
                {...field}
                ref={undefined}
                variant="inline"
                label={t("planning.date")}
                format="yyyy. MM. dd."
                openTo="year"
                InputLabelProps={{ shrink: true, required: true }}
                autoOk
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.personalData.idCardNumber")}*
          </Typography>
          <Controller
            name="idCardNumber"
            defaultValue=""
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.personalData.addressCardNumber")}*
          </Typography>
          <Controller
            name="addressCardNumber"
            defaultValue=""
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.personalData.address")}*
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="address.country"
            defaultValue=""
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: true }}
                label={t("companySite.formValues.address.country")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="address.zipCode"
            defaultValue={""}
            rules={{
              required: t("validation.required").toString(),
              validate: value => {
                if (value && !value.toString().match(NUMBER_FORMAT)) {
                  return t("common:validation.numberFormat").toString();
                }
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: true }}
                label={t("companySite.formValues.address.zipCode")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="address.city"
            defaultValue={""}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: true }}
                label={t("companySite.formValues.address.city")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="address.areaName"
            defaultValue={""}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: true }}
                label={t("companySite.formValues.address.areaName")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="address.areaType"
            defaultValue={"UTCA"}
            rules={{ required: t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: true }}
                label={t("companySite.formValues.address.areaType")}
                select
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                {AREA_TYPES.map(area => (
                  <MenuItem key={area} value={area}>
                    {t(`common:areaType.${area}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="address.houseNumber"
            defaultValue={""}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label={t("companySite.formValues.address.houseNumber")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.personalData.tempAddress")}
            {isTempAddressRequired ? "*" : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="tempAddress.country"
            defaultValue=""
            rules={{ required: isTempAddressRequired && t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: isTempAddressRequired }}
                label={t("companySite.formValues.address.country")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="tempAddress.zipCode"
            defaultValue={""}
            rules={{
              required: isTempAddressRequired && t("validation.required").toString(),
              validate: value => {
                if (value && !value.toString().match(NUMBER_FORMAT)) {
                  return t("common:validation.numberFormat").toString();
                }
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: isTempAddressRequired }}
                label={t("companySite.formValues.address.zipCode")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="tempAddress.city"
            defaultValue={""}
            rules={{ required: isTempAddressRequired && t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: isTempAddressRequired }}
                label={t("companySite.formValues.address.city")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="tempAddress.areaName"
            defaultValue={""}
            rules={{ required: isTempAddressRequired && t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: isTempAddressRequired }}
                label={t("companySite.formValues.address.areaName")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="tempAddress.areaType"
            defaultValue={"UTCA"}
            rules={{ required: isTempAddressRequired && t("validation.required").toString() }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true, required: isTempAddressRequired }}
                label={t("companySite.formValues.address.areaType")}
                select
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                {AREA_TYPES.map(area => (
                  <MenuItem key={area} value={area}>
                    {t(`common:areaType.${area}`)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="tempAddress.houseNumber"
            defaultValue={""}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                InputLabelProps={{ shrink: true }}
                label={t("companySite.formValues.address.houseNumber")}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Box maxWidth={250} margin="auto">
            <Typography style={{ fontWeight: "bold", paddingBottom: 4 }}>
              {t("jobForm.personalData.profilePic")}
            </Typography>
            {idImage && <img src={imgSrc} alt="" style={{ maxHeight: 100, aspectRatio: "auto" }} />}
            <ButtonBase component="label" style={{ borderRadius: 10 }}>
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                {...register("file")}
              />
              <Card variant="outlined">
                <Box display="flex" alignItems="center" gridGap={12} p={"8px 16px"}>
                  <FontAwesomeIcon icon={faUserPlus} style={{ width: 24, height: 24 }} />
                  <Typography style={{ textTransform: "uppercase", fontWeight: "bold" }}>
                    {t("jobForm.personalData.selectPicture")}
                  </Typography>
                </Box>
              </Card>
            </ButtonBase>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ContactDataCard;
