import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, RemoveCircle } from "@material-ui/icons";
import { COLORS } from "config/theme";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const LanguageDataCard = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { control } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: "jobLanguageSkillEntries",
  });

  return (
    <Card style={{ padding: 16 }}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.languageData.title")}
      </Typography>
      {fields.map((field, index) => {
        return (
          <Grid key={field.id} container spacing={1} style={{ marginBottom: 8 }}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography style={{ fontWeight: "bold", fontSize: 16, color: COLORS.mainLight }}>
                  {t("jobForm.languageData.row", { number: index + 1 })}
                </Typography>
                <Tooltip title={t("common:button.delete")}>
                  <IconButton
                    size="small"
                    style={{ color: COLORS.mainLight }}
                    onClick={() => remove(index)}
                  >
                    <RemoveCircle fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold", padding: 4 }}>
                {t("jobForm.languageData.language")}*
              </Typography>
              <Controller
                name={`jobLanguageSkillEntries.${index}.language`}
                defaultValue=""
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    onBlur={event => field.onChange(event?.target?.value?.trim())}
                    size="small"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold", padding: 4 }}>
                {t("jobForm.languageData.oralLevel")}*
              </Typography>
              <Controller
                name={`jobLanguageSkillEntries.${index}.oralLevel`}
                defaultValue=""
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    onBlur={event => field.onChange(event?.target?.value?.trim())}
                    select
                    size="small"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                  >
                    {["BASIC", "INTERMEDIATE", "ADVANCED"].map(level => (
                      <MenuItem key={level} value={level}>
                        {t(`jobForm.languageData.${level}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold", padding: 4 }}>
                {t("jobForm.languageData.writtenLevel")}*
              </Typography>
              <Controller
                name={`jobLanguageSkillEntries.${index}.writtenLevel`}
                defaultValue=""
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    onBlur={event => field.onChange(event?.target?.value?.trim())}
                    select
                    size="small"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                  >
                    {["BASIC", "INTERMEDIATE", "ADVANCED"].map(level => (
                      <MenuItem key={level} value={level}>
                        {t(`jobForm.languageData.${level}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        );
      })}
      <Box style={{ textAlign: "center", marginTop: 16 }}>
        <Button
          size="small"
          startIcon={<AddBox />}
          onClick={() =>
            append({
              language: "",
              oralLevel: "",
              writtenLevel: "",
            })
          }
        >
          {t("jobForm.languageData.add")}
        </Button>
      </Box>
    </Card>
  );
};

export default LanguageDataCard;
