import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AddBox, RemoveCircle } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { COLORS } from "config/theme";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const JobDataCard = () => {
  const { t } = useTranslation();
  const form = useFormContext();
  const { control } = form;

  const { append, fields, remove } = useFieldArray({
    control,
    name: "jobExperienceEntries",
  });

  return (
    <Card style={{ padding: 16 }}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.jobData.title")}
      </Typography>
      {fields.map((field, index) => {
        return (
          <Grid
            key={field.id}
            container
            spacing={1}
            alignItems="flex-end"
            style={{ marginBottom: 8 }}
          >
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography style={{ fontWeight: "bold", fontSize: 16, color: COLORS.mainLight }}>
                  {t("jobForm.jobData.row", { number: index + 1 })}
                </Typography>
                <Tooltip title={t("common:button.delete")}>
                  <IconButton
                    size="small"
                    style={{ color: COLORS.mainLight }}
                    onClick={() => remove(index)}
                  >
                    <RemoveCircle fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography style={{ fontWeight: "bold", padding: 4 }}>
                {t("jobForm.jobData.fromDate")}*
              </Typography>
              <Controller
                name={`jobExperienceEntries.${index}.fromDate`}
                rules={{
                  required: t("validation.required").toString(),
                }}
                defaultValue={null}
                render={({ field, fieldState }) => (
                  <KeyboardDatePicker
                    {...field}
                    ref={undefined}
                    openTo="year"
                    variant="inline"
                    format="yyyy. MM."
                    views={["year", "month"]}
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    autoOk
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography style={{ fontWeight: "bold", padding: 4 }}>
                {t("jobForm.jobData.toDate")}*
              </Typography>
              <Controller
                name={`jobExperienceEntries.${index}.toDate`}
                rules={{
                  required: t("validation.required").toString(),
                }}
                defaultValue={null}
                render={({ field, fieldState }) => (
                  <KeyboardDatePicker
                    {...field}
                    openTo="year"
                    views={["year", "month"]}
                    ref={undefined}
                    variant="inline"
                    format="yyyy. MM."
                    InputLabelProps={{
                      shrink: true,
                      required: true,
                    }}
                    autoOk
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography style={{ fontWeight: "bold", padding: 4 }}>
                {t("jobForm.jobData.experience")}*
              </Typography>
              <Controller
                name={`jobExperienceEntries.${index}.experience`}
                defaultValue=""
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    size="small"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography style={{ fontWeight: "bold", padding: 4 }}>
                {t("jobForm.jobData.jobTitle")}*
              </Typography>
              <Controller
                name={`jobExperienceEntries.${index}.jobTitle`}
                defaultValue=""
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    size="small"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography style={{ fontWeight: "bold", padding: 4 }}>
                {t("jobForm.jobData.reference")}*
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name={`jobExperienceEntries.${index}.referencePersonName`}
                defaultValue=""
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    size="small"
                    label={t("jobForm.jobData.referencePersonName")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name={`jobExperienceEntries.${index}.referencePersonPhone`}
                defaultValue=""
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="tel"
                    label={t("jobForm.jobData.referencePersonPhone")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name={`jobExperienceEntries.${index}.referencePersonEmail`}
                defaultValue=""
                rules={{
                  required: t("validation.required").toString(),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="email"
                    label={t("jobForm.jobData.referencePersonEmail")}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    InputLabelProps={{ shrink: true, required: true }}
                  />
                )}
              />
            </Grid>
          </Grid>
        );
      })}
      <Box style={{ textAlign: "center", marginTop: 16 }}>
        <Button
          size="small"
          startIcon={<AddBox />}
          onClick={() =>
            append({
              fromDate: null,
              toDate: null,
              jobTitle: "",
              experience: "",
              referencePersonName: "",
              referencePersonPhone: "",
              referencePersonEmail: "",
            })
          }
        >
          {t("jobForm.jobData.add")}
        </Button>
      </Box>
    </Card>
  );
};

export default JobDataCard;
