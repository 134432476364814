import axios from "config/axios";
import { GenericResponse } from "shared/common";
import { Address } from "shared/types";

export interface JobAdvertisement {
  companyAccountNumber: string;
  companyHqAddress: Address;
  companyInvoiceAddress: Address;
  companyName: string | null;
  companyTax: string;
  createdBy?: number;
  createdOn?: string;
  isExperienceFlexible: boolean;
  isLanguageSkillFlexible: boolean;
  languageSkill: string;
  requiredExperience: number;
  tenantId?: number;
  updatedBy?: number;
  updatedOn?: string;
  workAddress: Address;
  workManagerName: string;
  workManagerPhone: string;
}

const ENDPOINT = "/job-advertisement";

export const createJobAdvertisement = (
  param: JobAdvertisement,
  password: string,
  tenantId: number,
) =>
  axios.post<GenericResponse<JobAdvertisement>>(`${ENDPOINT}`, {
    jobAdvertisementEntry: param,
    password,
    tenantId,
  });
