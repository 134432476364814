import { Box, Card, Grid, TextField, Typography } from "@material-ui/core";
import { COLORS } from "config/theme";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const PersonalDataCard = () => {
  const { t } = useTranslation();

  return (
    <Card style={{ padding: 16 }}>
      <Typography
        style={{
          fontFamily: "Montserrat",
          fontSize: 20,
          fontWeight: "bold",
          paddingBottom: 8,
        }}
      >
        {t("jobForm.contactData.title")}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="email"
            defaultValue=""
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <Box>
                <Typography
                  style={{
                    fontWeight: "bold",
                    padding: 4,
                    color: fieldState.error ? COLORS.red : undefined,
                  }}
                >
                  {t("jobForm.contactData.email")}*
                </Typography>
                <TextField
                  {...field}
                  type="email"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.contactData.phone")}*
          </Typography>
          <Controller
            name="phone"
            defaultValue=""
            rules={{
              required: t("validation.required").toString(),
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="tel"
                size="small"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.contactData.phoneHu")}
          </Typography>
          <Controller
            name="phoneHu"
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                size="small"
                type="tel"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.contactData.phoneForeign")}
          </Typography>
          <Controller
            name="phoneForeign"
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="tel"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.contactData.viber")}
          </Typography>
          <Controller
            name="viber"
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="tel"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.contactData.facebook")}
          </Typography>
          <Controller
            name="facebook"
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography style={{ fontWeight: "bold", padding: 4 }}>
            {t("jobForm.contactData.instagram")}
          </Typography>
          <Controller
            name="instagram"
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                InputLabelProps={{ shrink: true, required: true }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default PersonalDataCard;
