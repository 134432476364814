import {
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Loading from "components/Loading";
import PasswordTextField from "components/PasswordTextField";
import { AREA_TYPES, NUMBER_FORMAT, PASSWORD_POLICY_REGEX } from "config/constants";
import { RootState } from "config/store";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouteComponentProps, useLocation } from "react-router";
import { createJobAdvertisement } from "shared/network/job-advertisement";
import { Address } from "shared/types";
import AcceptTos from "views/Report/AcceptTos";

type FormValues = {
  companyAccountNumber: string;
  companyHqAddress: Address;
  companyInvoiceAddress: Address;
  companyName: string | null;
  companyTax: string;
  isExperienceFlexible: boolean;
  isLanguageSkillFlexible: boolean;
  languageSkill: string;
  requiredExperience: number;
  workAddress: Address;
  workManagerName: string;
  workManagerPhone: string;
  password: string;
};

const JobAdCreate = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const query = new URLSearchParams(location.search);
  const tenantId = query.get("tenantId");

  const user = useSelector((state: RootState) => state?.authentication?.account?.user);
  const form = useForm();
  const { handleSubmit, watch } = form;
  const [loading, setLoading] = useState(false);
  const [isTosOk, setIsTosOk] = useState(false);

  async function onSubmit(values: FormValues) {
    setLoading(true);

    try {
      await createJobAdvertisement(values, values.password, tenantId ? Number(tenantId) : 1);
      enqueueSnackbar(t("jobForm.success"), { variant: "success" });
    } catch {
      enqueueSnackbar(t("jobForm.error"), { variant: "error" });
    }
    setLoading(false);
  }

  return (
    <Container maxWidth="md">
      <Loading open={loading} />
      {!user && (
        <Typography
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat",
            fontSize: 24,
            textAlign: "center",
            padding: 16,
          }}
        >
          {t("jobAd.pageTitle")}
        </Typography>
      )}
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box pb={2}>
            <Card style={{ padding: 16 }}>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontSize: 20,
                  fontWeight: "bold",
                  paddingBottom: 8,
                }}
              >
                {t("jobAd.title")}
              </Typography>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ fontWeight: "bold", padding: 4 }}>
                    {t("jobAd.companyName")}*
                  </Typography>
                  <Controller
                    name="companyName"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        size="small"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ fontWeight: "bold", padding: 4 }}>
                    {t("jobAd.companyTax")}*
                  </Typography>
                  <Controller
                    name="companyTax"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        size="small"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ fontWeight: "bold", padding: 4 }}>
                    {t("jobAd.companyAccountNumber")}*
                  </Typography>
                  <Controller
                    name="companyAccountNumber"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        size="small"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold", padding: 4 }}>
                    {t("jobAd.companyHqAddress")}*
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={4}>
                  <Controller
                    name="companyHqAddress.country"
                    defaultValue=""
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.country")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Controller
                    name="companyHqAddress.zipCode"
                    defaultValue={""}
                    rules={{
                      required: t("validation.required").toString(),
                      validate: value => {
                        if (value && !value.toString().match(NUMBER_FORMAT)) {
                          return t("common:validation.numberFormat").toString();
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.zipCode")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="companyHqAddress.city"
                    defaultValue={""}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.city")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="companyHqAddress.areaName"
                    defaultValue={""}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.areaName")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="companyHqAddress.areaType"
                    defaultValue={"UTCA"}
                    rules={{ required: t("validation.required").toString() }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.areaType")}
                        select
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      >
                        {AREA_TYPES.map(area => (
                          <MenuItem key={area} value={area}>
                            {t(`common:areaType.${area}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="companyHqAddress.houseNumber"
                    defaultValue={""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true }}
                        label={t("companySite.formValues.address.houseNumber")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold", padding: 4 }}>
                    {t("jobAd.companyInvoiceAddress")}
                    {"*"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={4}>
                  <Controller
                    name="companyInvoiceAddress.country"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.country")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Controller
                    name="companyInvoiceAddress.zipCode"
                    defaultValue={""}
                    rules={{
                      required: t("validation.required").toString(),
                      validate: value => {
                        if (value && !value.toString().match(NUMBER_FORMAT)) {
                          return t("common:validation.numberFormat").toString();
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.zipCode")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="companyInvoiceAddress.city"
                    defaultValue={""}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.city")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="companyInvoiceAddress.areaName"
                    defaultValue={""}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.areaName")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="companyInvoiceAddress.areaType"
                    defaultValue={"UTCA"}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.areaType")}
                        select
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      >
                        {AREA_TYPES.map(area => (
                          <MenuItem key={area} value={area}>
                            {t(`common:areaType.${area}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="companyInvoiceAddress.houseNumber"
                    defaultValue={""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true }}
                        label={t("companySite.formValues.address.houseNumber")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontWeight: "bold", padding: 4 }}>
                    {t("jobAd.workAddress")}
                    {"*"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={4}>
                  <Controller
                    name="workAddress.country"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.country")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                  <Controller
                    name="workAddress.zipCode"
                    defaultValue={""}
                    rules={{
                      required: t("validation.required").toString(),
                      validate: value => {
                        if (value && !value.toString().match(NUMBER_FORMAT)) {
                          return t("common:validation.numberFormat").toString();
                        }
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.zipCode")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="workAddress.city"
                    defaultValue={""}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.city")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="workAddress.areaName"
                    defaultValue={""}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.areaName")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="workAddress.areaType"
                    defaultValue={"UTCA"}
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true, required: true }}
                        label={t("companySite.formValues.address.areaType")}
                        select
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      >
                        {AREA_TYPES.map(area => (
                          <MenuItem key={area} value={area}>
                            {t(`common:areaType.${area}`)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Controller
                    name="workAddress.houseNumber"
                    defaultValue={""}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        InputLabelProps={{ shrink: true }}
                        label={t("companySite.formValues.address.houseNumber")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography style={{ fontWeight: "bold", padding: 4 }}>
                    {t("jobAd.workManager")}*
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="workManagerName"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        size="small"
                        label={t("jobAd.workManagerName")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="workManagerEmail"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        type="email"
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        size="small"
                        label={t("jobAd.workManagerEmail")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Controller
                    name="workManagerPhone"
                    defaultValue=""
                    rules={{
                      required: t("validation.required").toString(),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        onBlur={event => field.onChange(event?.target?.value?.trim())}
                        size="small"
                        type="tel"
                        label={t("jobAd.workManagerPhone")}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        InputLabelProps={{ shrink: true, required: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}></Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ fontWeight: "bold", padding: 4 }}>
                    {t("jobAd.requiredExperience")}*
                  </Typography>
                  <Box display="flex" gridGap={16}>
                    <Controller
                      name="requiredExperience"
                      defaultValue=""
                      rules={{
                        required: t("validation.required").toString(),
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          style={{ maxWidth: 100 }}
                          onBlur={event => field.onChange(event?.target?.value?.trim())}
                          size="small"
                          type="number"
                          onWheelCapture={e => (e.target as any).blur()}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          InputLabelProps={{ shrink: true, required: true }}
                        />
                      )}
                    />
                    <Controller
                      name="isExperienceFlexible"
                      defaultValue={false}
                      render={({ field: { onChange, value, ref } }) => (
                        <FormControlLabel
                          label={t("jobAd.isExperienceFlexible")}
                          control={
                            <Checkbox
                              inputRef={ref}
                              size="small"
                              color="primary"
                              checked={value}
                              onChange={(e, checked) => {
                                onChange(checked);
                              }}
                            />
                          }
                        />
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography style={{ fontWeight: "bold", padding: 4 }}>
                    {t("jobAd.languageSkill")}*
                  </Typography>
                  <Box display="flex" gridGap={16}>
                    <Controller
                      name="languageSkill"
                      defaultValue=""
                      rules={{
                        required: t("validation.required").toString(),
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          style={{ maxWidth: 200 }}
                          onBlur={event => field.onChange(event?.target?.value?.trim())}
                          size="small"
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          InputLabelProps={{ shrink: true, required: true }}
                        />
                      )}
                    />
                    <Controller
                      name="isLanguageSkillFlexible"
                      defaultValue={false}
                      render={({ field: { onChange, value, ref } }) => (
                        <FormControlLabel
                          label={t("jobAd.isLanguageSkillFlexible")}
                          control={
                            <Checkbox
                              inputRef={ref}
                              size="small"
                              color="primary"
                              checked={value}
                              onChange={(e, checked) => {
                                onChange(checked);
                              }}
                            />
                          }
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Box>
          <Box
            pb={2}
            style={{
              marginBottom: 16,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card
              style={{
                padding: 16,
                marginBottom: 16,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box display="block" style={{ maxWidth: 400 }}>
                <Controller
                  name="password"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t("validation.required"),
                    },
                    validate: value => {
                      if (value && !value.match(PASSWORD_POLICY_REGEX)) {
                        return t("common:passwordPolicy").toString();
                      }
                      if (value !== watch("password2")) {
                        return t("common:passwordMismatch").toString();
                      }
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <PasswordTextField
                      {...field}
                      onBlur={event => field.onChange(event?.target?.value?.trim())}
                      ref={undefined}
                      type="password"
                      label={t("registration.formValues.password")}
                      style={{ marginBottom: 16 }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
                <Controller
                  name="password2"
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: t("validation.required"),
                    },
                    validate: value => {
                      if (value && !value.match(PASSWORD_POLICY_REGEX)) {
                        return t("common:passwordPolicy").toString();
                      }
                      if (value !== watch("password")) {
                        return t("common:passwordMismatch").toString();
                      }
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <PasswordTextField
                      {...field}
                      onBlur={event => field.onChange(event?.target?.value?.trim())}
                      ref={undefined}
                      type="passwordConfirmation"
                      label={t("registration.formValues.passwordConfirm")}
                      style={{ maxWidth: 400 }}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Box>
              <AcceptTos setIsTosOk={setIsTosOk} />
            </Card>
            <Button disabled={!isTosOk || loading} type="submit">
              {t("common:button.save")}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Container>
  );
};

export default JobAdCreate;
